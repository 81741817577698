<template>
	<div class="FM_page-investDetail">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="alert alert-danger" role="alert" v-show="formStatus.success === 0">
						{{ formStatus.errorMsg }}
					</div>
					<div class="d-flex justify-content-center" style="padding: 30px 0;" v-if="spinner">
						<div class="spinner-border text-light" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="!spinner">
				<div class="col-12">
					<div class="FM_fundLogo">
						<img src="~@/assets/icon-fund/icon_holy_grail_loop.gif" class="img" width="120" :alt="fund.name" v-if="fund.code === 'fomo_fund'">
						<img src="~@/assets/icon-fund/icon_shield_loop.gif" class="img" width="120" style="left: -3px;" :alt="fund.name" v-if="fund.code === 'safe_fund'">
						<img src="~@/assets/icon-fund/icon_crown_loop.gif" class="img" width="120" style="left: -2px;" :alt="fund.name" v-if="fund.code === 'btc_fund'">
						<img src="~@/assets/icon-fund/icon_sword_loop.gif" class="img" width="120" style="left: 10px;" :alt="fund.name" v-if="fund.code === 'eth_fund'">
					</div>
				</div>
				<div class="col-6">
					<h3 class="text-title-fund text-right">{{ fund.name.replace('Fund', '') }}</h3>
				</div>
				<div class="col-6 col-lg-3">
					<p class="text-apy" v-if="fund.code === 'fomo_fund'">&nbsp;</p>
					<!-- <p class="text-apy" v-else>{{ (fund.apy/100) > 0 ? 'APY%' : '' }} {{ formatNumber((fund.apy/100).toFixed(2)) }}%</p> -->
					<p class="text-apy" v-if="fund.code === 'safe_fund'">Fixed {{ formatNumber((fund.apy/100).toFixed(2)) }}%</p>
					<p class="text-apy" v-if="fund.code === 'btc_fund'">Adjustable {{ formatNumber(fund.apy/100) }}%</p>
					<p class="text-apy" v-if="fund.code === 'eth_fund'">Adjustable {{ formatNumber(fund.apy/100) }}%</p>
				</div>
				<div class="col-12" v-if="portfolio.invested">
					<table class="table-responsive FM_table-fundInfo">
						<table class="table table-borderless">
							<tbody>
							<tr>
								<th width="50%">INVESTED</th>
								<td width="50%">
									<span v-if="currencyPanel.toCurrencyType === 'normal'">{{ prefix + amount + suffix }}</span>
									<span v-if="currencyPanel.toCurrencyType === 'USDT'">≒ ${{ (amount * (fund.currency_price/1000000)).toFixed(2) }}</span>
								</td>
							</tr>
							<tr>
								<th>P/L($)</th>
								<td :class="{'FM_text-green1': (fund.profit / currencyPoint[fund.currency].digits) >= 0, 'text-danger': (fund.profit / currencyPoint[fund.currency].digits) < 0}">
									<span class="minus">{{ (fund.profit / currencyPoint[fund.currency].digits) >= 0 ? '+' : '' }}</span>
									<span v-if="currencyPanel.toCurrencyType === 'normal'">{{ prefix + profit + suffix }}</span>
									<span v-if="currencyPanel.toCurrencyType === 'USDT'">≒ ${{ (profit * (fund.currency_price/1000000)).toFixed(2) }}</span>
								</td>
							</tr>
							<tr>
								<th>VALUE</th>
								<td>
									<span v-if="currencyPanel.toCurrencyType === 'normal'">{{ prefix + value + suffix }}</span>
									<span v-if="currencyPanel.toCurrencyType === 'USDT'">≒ ${{ (value * (fund.currency_price/1000000)).toFixed(2) }}</span>
								</td>
							</tr>
							</tbody>
						</table>
					</table>
					<p class="text-light text-right FM_text-gray6" v-if="(fund.code === 'btc_fund' || fund.code === 'eth_fund')">
						USDT prices are estimations.
						This fund is priced in {{ fund.currency }}.
					</p>
				</div>
				<div class="col-12" v-if="portfolio.invested">
					<div class="FM_btnGroup-fund">
						<button type="button" :class="{'active': tab === 'Overview'}" @click="tab = 'Overview'">Overview</button>
						<button type="button" :class="{'active': tab === 'Portfolio'}" @click="tab = 'Portfolio'">Portfolio</button>
					</div>
					<div class="FM_btnGroup-fund position-fixed" v-show="showFixedTab">
						<button type="button" :class="{'active': tab === 'Overview'}" @click="tab = 'Overview'">Overview</button>
						<button type="button" :class="{'active': tab === 'Portfolio'}" @click="tab = 'Portfolio'">Portfolio</button>
					</div>
				</div>
				<div class="col-12" v-show="tab === 'Overview'">
					<div class="FM_table-fund table-responsive">
						<h6>KEY STATS</h6>
						<table class="table table-striped table-borderless">
							<tbody>
							<tr>
								<th scope="row" width="200">Period</th>
								<td>1 year</td>
							</tr>
							<tr>
								<th scope="row">Suitable for</th>
								<td>
									{{ fund.code === 'fomo_fund' ? 'Adventurous Investors' : ''}}
									{{ fund.code === 'safe_fund' ? 'DeFi Newbie' : ''}}
									{{ fund.code === 'btc_fund' || fund.code === 'eth_fund' ? 'Robust Investors' : '' }}
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="FM_chartBox" v-if="(fund.code === 'safe_fund' || fund.code === 'btc_fund' || fund.code === 'eth_fund') && fund.value > 0">
						<div id="projectionChart">
							<apexchart ref="realtimeChart" type="line" height="350" :options="projectionChart.chartOptions" :series="projectionChart.series"></apexchart>
						</div>
						<p class="text-tip">
							THIS IS A PROJECTION GENERATED WITH THE FLOATING INTEREST RATE AND DOES NOT REFLECT ACTUAL RETURNS.
						</p>
					</div>
					<div class="FM_chartBox" v-if="!spinner && fund.code === 'fomo_fund'">
						<div id="performanceChart">
							<apexchart ref="realtimeChart" type="line" height="350" :options="performanceChart.chartOptions" :series="performanceChart.series"></apexchart>
						</div>
						<p class="text-tip">
							PERFORMANCE IS BASED ON $1000 USDT INITIAL INVESTMENT. <br>
							PAST PERFORMANCE IS NOT INDICATIVE OF FUTURE RESULTS.
						</p>
					</div>
					<div class="FM_chartBox" v-if="fund.code === 'fomo_fund' && assetAllocationChart.series.length > 0">
						<div class="container">
							<div class="row">
								<div class="col-lg-6">
									<div id="assetAllocationChart">
										<apexchart ref="realtimeChart" type="pie" :options="assetAllocationChart.chartOptions" :series="assetAllocationChart.series"></apexchart>
									</div>
								</div>
								<div class="col-lg-6 align-self-center">
									<p class="FM_pieChartList" v-for="(item, key) in assetAllocationChart.chartOptions.labels" :key="key">
										<span class="dot" :style="{'background': assetAllocationChart.chartOptions.colors[key%5]}"></span>
										{{ item }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="FM_table-fund table-responsive">
						<h6>Fee Structure</h6>
						<table class="table table-striped table-borderless">
							<tbody>
							<tr>
								<th scope="row" width="200">Management Fee</th>
								<td>{{ fund.code === 'fomo_fund' ? '0.5% of initial Investment every quarter' : 'No Fee'}}</td>
							</tr>
							<tr>
								<th scope="row">Profit-Sharing Incentive</th>
								<td style="vertical-align: middle">{{ fund.code === 'fomo_fund' ? '20% of profit' : 'N/A'}}</td>
							</tr>
							<tr>
								<th scope="row">Early Withdraw Fee<br>(if < 365 days)</th>
								<td>{{ fund.code === 'fomo_fund' ? '2% of total asset' : 'No Fee'}}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12" v-show="tab === 'Portfolio'">
					<div class="FM_table-fund table-portfolio table-responsive" v-if="!spinnerPortfolio" >
						<h6>MY INVESTMENT IN <span class="UW_text-primary">{{ fund.code.replace('_', ' ').toUpperCase() }}</span></h6>
						<table class="table table-striped table-borderless">
							<thead>
							<tr>
								<th>Investment Date</th>
								<th>Amt. Invested (≒USDT)</th>
								<th>P/L($)</th>
								<th>P/L(%)</th>
								<th>Current Value (≒USDT)</th>
							</tr>
							</thead>
							<tbody>
							<tr v-if="portfolio.transactions && portfolio.transactions.active" v-for="(item, key) in portfolio.transactions.active" :key="key">
								<td>{{ moment(item.create_time * 1000).format('YYYY-MM-DD') }}</td>
								<td>{{ formatNumber((item.amount_usd/currencyPoint['USDT'].digits).toFixed(currencyPoint['USDT'].fixed)) }}</td>
								<td :class="{'FM_text-green1': item.profit > 0, 'text-danger': item.profit < 0}">
									{{ formatNumber((item.profit_usd/currencyPoint['USDT'].digits).toFixed(currencyPoint['USDT'].fixed)) }}
								</td>
								<td :class="{'FM_text-green1': item.profit > 0, 'text-danger': item.profit < 0}">
									{{ item.profit_rate === '0' && item.profit > 0 ? '< 0.01%': (item.profit_rate/100).toFixed(2) + '%' }}
								</td>
								<td>
									{{ formatNumber((item.value_usd/currencyPoint['USDT'].digits).toFixed(currencyPoint['USDT'].fixed)) }}
								</td>
							</tr>
							<tr v-if="!portfolio.transactions.active || portfolio.transactions.active.length == 0">
								<td colspan="5" class="text-center">No Data</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="FM_table-fund table-portfolio table-responsive" v-if="!spinnerPortfolio">
						<h6>CLOSED</h6>
						<table class="table table-striped table-borderless">
							<thead>
							<tr>
								<th>Investment Date</th>
								<th>Amt. Invested (≒USDT)</th>
								<th>P/L($)</th>
								<th>P/L(%)</th>
							</tr>
							</thead>
							<tbody>
							<tr v-if="portfolio.transactions && portfolio.transactions.records" v-for="item in portfolio.transactions.records">
								<td>{{ moment(item.create_time * 1000).format('YYYY-MM-DD') }}</td>
								<td>
									{{ item.action === 'IN' ? '+' : '-' }}
									{{ formatNumber((item.amount_usd/currencyPoint['USDT'].digits).toFixed(currencyPoint['USDT'].fixed)) }}
								</td>
								<td :class="{'FM_text-green1': item.profit > 0, 'text-danger': item.profit < 0}">
									{{ item.action === 'IN' ? '-' : formatNumber((item.profit_usd/currencyPoint['USDT'].digits).toFixed(currencyPoint['USDT'].fixed)) }}
								</td>
								<td :class="{'FM_text-green1': item.profit > 0, 'text-danger': item.profit < 0}">
									{{ item.action === 'IN' ? '-' : '' }}
									{{ item.action === 'OUT' && item.profit_rate === '0' && item.profit > 0 ? '< 0.01%': '' }}
									{{ item.action === 'OUT' && item.profit_rate > 0 && item.profit > 0 ? (item.profit_rate/100).toFixed(2) + '%' : '' }}
								</td>
							</tr>
							<tr v-if="!portfolio.transactions.records || portfolio.transactions.records.length == 0">
								<td colspan="5" class="text-center">No Data</td>
							</tr>
							</tbody>
							<tfoot>
							<tr v-if="portfolio.transactions.records">
								<th colspan="2" class="text-dark font-weight-bold">REALIZED P&L</th>
								<th colspan="2" :class="{'UW_text-green1': portfolio.summary.records_profit > 0, 'UW_text-danger': portfolio.summary.records_profit < 0}">{{ (portfolio.summary.records_profit/currencyPoint[fund.currency].digits).toFixed(currencyPoint[fund.currency].fixed) }}</th>
							</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { urlReplace, formatNumber } from "@/utils/index.js"
import { app } from "@/main.js"

export default {
	name: "Detail",
	data() {
		return{
			performanceChart: {
				series: [
					{
						name: "Value",
						data: []
					}
				],
				chartOptions: {
					chart: {
						height: 350,
						type: 'line',
						foreColor: '#fff',
						zoom: {
							enabled: false
						}
					},
					stroke: {
						curve: 'smooth'
					},
					title: {
						text: ' PERFORMANCE',
						align: 'left',
						style: {
							fontSize:  '16px',
						}
					},
					grid: {
						// row: {
						// 	colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						// 	opacity: 0.5
						// },
					},
					xaxis: {
						categories: [],
						type: 'datetime',
					}
				},
			},
			assetAllocationChart: {
				series: [],
				// series: [44, 55, 13, 33],
				chartOptions: {
					title: {
						text: 'ASSET ALLOCATION',
						align: 'left',
						style: {
							fontSize:  '16px',
						}
					},
					chart: {
						type: 'pie',
						foreColor: '#fff',
					},
					plotOptions: {
						pie: {
							// customScale: 1.1,
						}
					},
					labels: [],
					// labels: ['Apple', 'Mango', 'Orange', 'Watermelon'],
					colors: ['#50BDF5', '#E95A5D', '#FEDD8C', '#B8DF7C', '#D4C4F0'],
					legend: {
						show: false,
						// position: 'bottom',
						// showForSingleSeries: true,
						// horizontalAlign: 'left',
					},
					tooltip: {
						// enabled: false,
						y: {
							formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
								return value + '%'
							},
							// title: {
							// 	formatter: (seriesName) => seriesName + '%',
							// },
						}
					}
					// responsive: [
					// 	{
					// 		breakpoint: 480,
					// 		options: {
					// 			chart: {
					// 				width: 200
					// 			},
					// 			legend: {
					// 				position: 'bottom'
					// 			}
					// 		}
					// 	}
					// ]
				},
			},
			projectionChart: {
				series: [
					{
						name: "Value",
						data: []
					}
				],
				chartOptions: {
					chart: {
						height: 350,
						type: 'line',
						foreColor: '#fff',
						zoom: {
							enabled: false
						}
					},
					stroke: {
						curve: 'straight'
					},
					title: {
						text: 'PROJECTION',
						align: 'left',
						style: {
							fontSize:  '16px',
						}
					},
					grid: {
						// row: {
						// 	colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						// 	opacity: 0.5
						// },
					},
					xaxis: {
						categories: ['Today', '30 Days', '60 Days', '90 Days'],
					}
				},
			},
			id: '',
			spinner: true,
			spinnerPortfolio: false,
			tab: 'Overview', // Overview, Portfolio
			showFixedTab: false,
			imgOpacity: 1,
			currencyPoint: {
				USDT: {
					digits: 1000000,
					fixed: 2
				},
				BTC: {
					digits: 100000000,
					fixed: 8
				},
				ETH: {
					digits: 100000000,
					fixed: 8
				},
			},
			fund: {
				name: '',
				apy: '',
				time: '',
				value: 0,
				amount: 0,
				profit: 0,
				code: '',
				currency: 'USDT',
				currency_price: 0
			},
			formStatus: {
				spinner: false,
				success: null,
				errorMsg: ''
			},
			portfolio: {},
			currencyPanel: {
				show: false,
				toCurrencyType: 'normal', // normal, USDT
			}
		}
	},
	computed: {
		amount () {
			let num = this.formatNumber((this.fund.amount / this.currencyPoint[this.fund.currency].digits).toFixed(this.currencyPoint[this.fund.currency].fixed));
			return num;
		},
		profit () {
			let num = this.formatNumber((this.fund.profit / this.currencyPoint[this.fund.currency].digits).toFixed(this.currencyPoint[this.fund.currency].fixed));
			return num;
		},
		value () {
			let num = this.formatNumber((this.fund.value / this.currencyPoint[this.fund.currency].digits).toFixed(this.currencyPoint[this.fund.currency].fixed));
			return num;
		},
		prefix () {
			let prefix = '';
			switch (this.fund.currency) {
				case 'USDT':
					prefix = '$ ';
					break;
				case 'BTC':
					prefix = '';
					break;
				case 'ETH':
					prefix = '';
					break;
				default:
					prefix = '';
					break;
			}

			return prefix;
		},
		suffix () {
			let suffix = '';
			switch (this.fund.currency) {
				case 'USDT':
					suffix = '';
					break;
				case 'BTC':
					suffix = ' BTC';
					break;
				case 'ETH':
					suffix = ' ETH';
					break
				default:
					suffix = '';
			}

			return suffix;
		}
	},
	created() {
		this.id = this.$route.params.id;
		this.getData();
		this.getPortfolio();

		// for tab
		let _this = this;
		const checkpoint = 200;
		$(window).on('scroll', function(){
			// for tab fixed
			if($(this).scrollTop() > 490) {
				_this.showFixedTab = true;
			} else {
				_this.showFixedTab = false;
			}
		});
		this.formatNumber('25000.00')
	},
	methods: {
		formatNumber,
		getData() {
			this.spinner = true;

			Ajax(
				API.fundInfo.method,
				urlReplace(API.fundInfo.url, { id: this.id }),
			).then(result => {
				if (result.success !== 1) {
					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
				}
				// set basic data
				this.fund.name = result.data.name;
				this.fund.apy = result.data.apy;
				this.fund.time = result.data.timestamp;
				this.fund.value = result.data.profile.value;
				this.fund.amount = result.data.profile.amount;
				this.fund.profit = result.data.profile.profit;
				this.fund.code = result.data.code;
				this.fund.currency = result.data.currency;
				this.fund.currency_price = result.data.currency_price;
				this.currencyPanel.show = result.data.currency !== 'USDT' ? true : false;

				// set Performance chart
				let performanceData = [];
				let category = [];
				let temp = result.data.performances.slice(-15);
				temp.forEach((item, i) => {
					performanceData.push((item.value/1000000).toFixed(6));
					// category.push(app.moment(item.time * 1000).format('MM-DD'))
					category.push(item.time * 1000)
				});
				this.performanceChart.series[0].data = performanceData;
				this.performanceChart.chartOptions.xaxis.categories = category;

				// set asset allocation
				let assetData = [];
				let assetName = [];
				result.data.components.forEach((item, i) => {
					assetData.push((item.percentage)/100);
					assetName.push(item.asset + ' ' + ((item.percentage)/100).toString() + '%');
				});
				this.assetAllocationChart.series = assetData;
				this.assetAllocationChart.chartOptions.labels = assetName;

				this.generateProjectionChart();

				this.spinner = false;
			});
		},
		getPortfolio() {
			this.spinnerPortfolio = true;

			Ajax(
				API.fundPortfolio.method,
				urlReplace(API.fundPortfolio.url, { id: this.id }),
			).then(result => {
				if (result.success !== 1) {
					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
				}
				this.portfolio = result.data;

				this.spinnerPortfolio = false;
			});
		},
		generateProjectionChart() {
			if (this.fund.value <= 0) {
				return;
			}

			let arr = [];
			let startingValue = (this.fund.value / this.currencyPoint[this.fund.currency].digits);
			let copy = startingValue.toFixed(this.currencyPoint[this.fund.currency].fixed);
			// 第一個 100 是將原本API收到的數字 800 除以 100 等 8％
			// 第二個 100 是將8%變成0.08，好讓等會計算可以正確
			let dayEarn = this.fund.apy/100/365/100;
			let seriesDays = [30, 60, 90];

			arr.push(copy);
			for(let i = 0; i < seriesDays.length; i++) {
				arr.push((startingValue + startingValue * dayEarn * seriesDays[i]).toFixed(this.currencyPoint[this.fund.currency].fixed))
			}
			this.projectionChart.series[0].data = arr;
		}
	}
}
</script>